import { __assign } from 'tslib';
import { isSVGComponent } from './is-svg-component.js';
import { createUseRender } from '../use-render.js';
import { svgMotionConfig } from '../../svg/config-motion.js';
import { htmlMotionConfig } from '../../html/config-motion.js';

function createDomMotionConfig(defaultFeatures, Component, forwardMotionProps) {
    var baseConfig = isSVGComponent(Component)
        ? svgMotionConfig
        : htmlMotionConfig;
    return __assign(__assign({}, baseConfig), { defaultFeatures: defaultFeatures, useRender: createUseRender(Component, forwardMotionProps) });
}

export { createDomMotionConfig };
