import { useContext, useEffect } from 'react';
import { __assign } from 'tslib';
import { MotionConfigContext } from '../../context/MotionConfigContext.js';
import { useVisualElementContext } from '../../context/MotionContext/index.js';
import { PresenceContext } from '../../context/PresenceContext.js';
import { useConstant } from '../../utils/use-constant.js';
import { isPresent } from '../../components/AnimatePresence/use-presence.js';
import { LayoutGroupContext } from '../../context/LayoutGroupContext.js';
import { useIsomorphicLayoutEffect } from '../../utils/use-isomorphic-effect.js';
import { useSnapshotOnUnmount } from '../features/layout/use-snapshot-on-unmount.js';

function useLayoutId(_a) {
    var layoutId = _a.layoutId;
    var layoutGroupId = useContext(LayoutGroupContext);
    return layoutGroupId && layoutId !== undefined
        ? layoutGroupId + "-" + layoutId
        : layoutId;
}
function useVisualElement(isStatic, visualState, createVisualElement, props) {
    var config = useContext(MotionConfigContext);
    var parent = useVisualElementContext();
    var presenceContext = useContext(PresenceContext);
    var layoutId = useLayoutId(props);
    var visualElement = useConstant(function () {
        return createVisualElement(isStatic, {
            visualState: visualState,
            parent: parent,
            props: __assign(__assign({}, props), { layoutId: layoutId }),
            presenceId: presenceContext === null || presenceContext === void 0 ? void 0 : presenceContext.id,
            blockInitialAnimation: (presenceContext === null || presenceContext === void 0 ? void 0 : presenceContext.initial) === false,
        });
    });
    useIsomorphicLayoutEffect(function () {
        visualElement.setProps(__assign(__assign(__assign({}, config), props), { layoutId: layoutId }));
        visualElement.isPresent = isPresent(presenceContext);
        visualElement.isPresenceRoot =
            !parent || parent.presenceId !== (presenceContext === null || presenceContext === void 0 ? void 0 : presenceContext.id);
        /**
         * Fire a render to ensure the latest state is reflected on-screen.
         */
        visualElement.syncRender();
    });
    useEffect(function () {
        var _a;
        /**
         * In a future refactor we can replace the features-as-components and
         * have this loop through them all firing "effect" listeners
         */
        (_a = visualElement.animationState) === null || _a === void 0 ? void 0 : _a.animateChanges();
    });
    /**
     * If this component is a child of AnimateSharedLayout, we need to snapshot the component
     * before it's unmounted. This lives here rather than in features/layout/Measure because
     * as a child component its unmount effect runs after this component has been unmounted.
     */
    useSnapshotOnUnmount(visualElement);
    return visualElement;
}

export { useVisualElement };
