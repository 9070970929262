import { usePanGesture } from './use-pan-gesture.js';
import { useTapGesture } from './use-tap-gesture.js';
import { useHoverGesture } from './use-hover-gesture.js';
import { useFocusGesture } from './use-focus-gesture.js';

/**
 * Add pan and tap gesture recognition to an element.
 *
 * @param props - Gesture event handlers
 * @param ref - React `ref` containing a DOM `Element`
 * @public
 */
function useGestures(props, visualElement) {
    usePanGesture(props, visualElement);
    useTapGesture(props, visualElement);
    useHoverGesture(props, visualElement);
    useFocusGesture(props, visualElement);
}

export { useGestures };
