import { __rest } from 'tslib';
import { makeRenderlessComponent } from '../utils/make-renderless-component.js';
import { useGestures } from '../../gestures/use-gestures.js';
import { gestureProps } from '../../gestures/types.js';

var GestureComponent = makeRenderlessComponent(function (_a) {
    var visualElement = _a.visualElement, props = __rest(_a, ["visualElement"]);
    useGestures(props, visualElement);
});
/**
 * @public
 */
var Gestures = {
    key: "gestures",
    shouldRender: function (props) {
        return gestureProps.some(function (key) { return props.hasOwnProperty(key); });
    },
    getComponent: function () { return GestureComponent; },
};

export { Gestures };
