import { __assign } from 'tslib';
import { getDefaultValueType } from '../dom/utils/value-types.js';
import { isTransformProp } from '../html/utils/transform.js';
import { buildSVGAttrs } from './utils/build-attrs.js';
import { visualElement } from '../index.js';
import { scrapeMotionValuesFromProps } from './utils/scrape-motion-values.js';
import { htmlConfig } from '../html/visual-element.js';
import { camelToDash } from '../dom/utils/camel-to-dash.js';
import { camelCaseAttributes } from './utils/camel-case-attrs.js';

function renderSVG(element, renderState) {
    htmlConfig.render(element, renderState);
    for (var key in renderState.attrs) {
        element.setAttribute(!camelCaseAttributes.has(key) ? camelToDash(key) : key, renderState.attrs[key]);
    }
}
var svgVisualElement = visualElement(__assign(__assign({}, htmlConfig), { getBaseTarget: function (props, key) {
        return props[key];
    },
    readValueFromInstance: function (domElement, key) {
        var _a;
        if (isTransformProp(key)) {
            return ((_a = getDefaultValueType(key)) === null || _a === void 0 ? void 0 : _a.default) || 0;
        }
        key = !camelCaseAttributes.has(key) ? camelToDash(key) : key;
        return domElement.getAttribute(key);
    },
    scrapeMotionValuesFromProps: scrapeMotionValuesFromProps,
    build: function (_element, renderState, latestValues, projection, layoutState, options, props) {
        buildSVGAttrs(renderState, latestValues, projection, layoutState, options, props.transformTemplate);
    }, render: renderSVG }));

export { renderSVG, svgVisualElement };
