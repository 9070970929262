import { useContext, createElement } from 'react';
import { __spread, __assign } from 'tslib';
import { MotionConfigContext } from '../../context/MotionConfigContext.js';

/**
 * Load features via renderless components based on the provided MotionProps.
 * TODO: Look into porting this to a component-less appraoch.
 */
function useFeatures(defaultFeatures, visualElement, props) {
    var plugins = useContext(MotionConfigContext);
    var allFeatures = __spread(defaultFeatures, plugins.features);
    var numFeatures = allFeatures.length;
    var features = [];
    // Decide which features we should render and add them to the returned array
    for (var i = 0; i < numFeatures; i++) {
        var _a = allFeatures[i], shouldRender = _a.shouldRender, key = _a.key, getComponent = _a.getComponent;
        if (shouldRender(props)) {
            var Component = getComponent(props);
            Component &&
                features.push(createElement(Component, __assign({ key: key }, props, { visualElement: visualElement })));
        }
    }
    return features;
}

export { useFeatures };
