import { useContext, useEffect } from 'react';
import { __read } from 'tslib';
import { PresenceContext } from '../../context/PresenceContext.js';
import { usePresence } from '../../components/AnimatePresence/use-presence.js';
import { AnimationType } from '../../render/utils/types.js';
import { makeRenderlessComponent } from '../utils/make-renderless-component.js';
import { checkShouldInheritVariant } from '../utils/should-inherit-variant.js';

/**
 * TODO: This component is quite small and no longer directly imports animation code.
 * It could be a candidate for folding back into the main `motion` component.
 */
var ExitComponent = makeRenderlessComponent(function (props) {
    var custom = props.custom, visualElement = props.visualElement;
    var _a = __read(usePresence(), 2), isPresent = _a[0], onExitComplete = _a[1];
    var presenceContext = useContext(PresenceContext);
    useEffect(function () {
        var _a, _b;
        var animation = (_a = visualElement.animationState) === null || _a === void 0 ? void 0 : _a.setActive(AnimationType.Exit, !isPresent, { custom: (_b = presenceContext === null || presenceContext === void 0 ? void 0 : presenceContext.custom) !== null && _b !== void 0 ? _b : custom });
        !isPresent && (animation === null || animation === void 0 ? void 0 : animation.then(onExitComplete));
    }, [isPresent]);
});
/**
 * @public
 */
var Exit = {
    key: "exit",
    shouldRender: function (props) { return !!props.exit && !checkShouldInheritVariant(props); },
    getComponent: function () { return ExitComponent; },
};

export { Exit };
