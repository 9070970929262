import { useEffect } from 'react';
import { makeRenderlessComponent } from '../utils/make-renderless-component.js';
import { isAnimationControls } from '../../animation/animation-controls.js';
import { createAnimationState } from '../../render/utils/animation-state.js';

var AnimationState = makeRenderlessComponent(function (props) {
    var visualElement = props.visualElement, animate = props.animate;
    /**
     * We dynamically generate the AnimationState manager as it contains a reference
     * to the underlying animation library. We only want to load that if we load this,
     * so people can optionally code split it out using the `m` component.
     */
    visualElement.animationState || (visualElement.animationState = createAnimationState(visualElement));
    /**
     * Subscribe any provided AnimationControls to the component's VisualElement
     */
    if (isAnimationControls(animate)) {
        useEffect(function () { return animate.subscribe(visualElement); }, [animate]);
    }
});
/**
 * @public
 */
var Animation = {
    key: "animation",
    shouldRender: function () { return true; },
    getComponent: function (_a) {
        var animate = _a.animate, whileHover = _a.whileHover, whileFocus = _a.whileFocus, whileTap = _a.whileTap, whileDrag = _a.whileDrag, exit = _a.exit, variants = _a.variants;
        return animate ||
            whileHover ||
            whileFocus ||
            whileTap ||
            whileDrag ||
            exit ||
            variants
            ? AnimationState
            : undefined;
    },
};

export { Animation };
