import { createHtmlRenderState } from './utils/create-render-state.js';
import { scrapeMotionValuesFromProps } from './utils/scrape-motion-values.js';
import { htmlVisualElement } from './visual-element.js';
import { makeUseVisualState } from '../../motion/utils/use-visual-state.js';

var htmlMotionConfig = {
    createVisualElement: function (isStatic, options) {
        return htmlVisualElement(options, { enableHardwareAcceleration: !isStatic });
    },
    useVisualState: makeUseVisualState({
        scrapeMotionValuesFromProps: scrapeMotionValuesFromProps,
        createRenderState: createHtmlRenderState,
    }),
};

export { htmlMotionConfig };
