import { __rest } from 'tslib';
import { useDrag } from '../../gestures/drag/use-drag.js';
import { makeRenderlessComponent } from '../utils/make-renderless-component.js';

var Component = makeRenderlessComponent(function (_a) {
    var visualElement = _a.visualElement, props = __rest(_a, ["visualElement"]);
    return useDrag(props, visualElement);
});
/**
 * @public
 */
var Drag = {
    key: "drag",
    shouldRender: function (props) { return !!props.drag || !!props.dragControls; },
    getComponent: function () { return Component; },
};

export { Drag };
