import { useContext, useEffect } from 'react';
import { __assign } from 'tslib';
import { MotionConfigContext } from '../../context/MotionConfigContext.js';
import { useConstant } from '../../utils/use-constant.js';
import { VisualElementDragControls } from './VisualElementDragControls.js';

/**
 * A hook that allows an element to be dragged.
 *
 * @internal
 */
function useDrag(props, visualElement) {
    var groupDragControls = props.dragControls;
    var transformPagePoint = useContext(MotionConfigContext).transformPagePoint;
    var dragControls = useConstant(function () {
        return new VisualElementDragControls({
            visualElement: visualElement,
        });
    });
    dragControls.setProps(__assign(__assign({}, props), { transformPagePoint: transformPagePoint }));
    // If we've been provided a DragControls for manual control over the drag gesture,
    // subscribe this component to it on mount.
    useEffect(function () { return groupDragControls && groupDragControls.subscribe(dragControls); }, [dragControls]);
    // Mount the drag controls with the visualElement
    useEffect(function () { return dragControls.mount(visualElement); }, []);
}

export { useDrag };
