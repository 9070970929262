import { useContext } from 'react';
import { SharedLayoutContext, FramerTreeLayoutContext, isSharedLayout } from '../../../context/SharedLayoutContext.js';
import { useIsomorphicLayoutEffect } from '../../../utils/use-isomorphic-effect.js';

function useSnapshotOnUnmount(visualElement) {
    var syncLayout = useContext(SharedLayoutContext);
    var framerSyncLayout = useContext(FramerTreeLayoutContext);
    useIsomorphicLayoutEffect(function () { return function () {
        if (isSharedLayout(syncLayout)) {
            syncLayout.remove(visualElement);
        }
        if (isSharedLayout(framerSyncLayout)) {
            framerSyncLayout.remove(visualElement);
        }
    }; }, []);
}

export { useSnapshotOnUnmount };
