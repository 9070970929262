import { createContext, useContext, useMemo, createElement } from 'react';
import { __rest, __spread } from 'tslib';

/**
 * @public
 */
var MotionConfigContext = createContext({
    transformPagePoint: function (p) { return p; },
    features: [],
    isStatic: false,
});
/**
 * MotionConfig can be used in combination with the `m` component to cut bundle size
 * and dynamically load only the features you use.
 *
 * ```jsx
 * import {
 *   m as motion,
 *   AnimationFeature,
 *   MotionConfig
 * } from "framer-motion"
 *
 * export function App() {
 *   return (
 *     <MotionConfig features={[AnimationFeature]}>
 *       <motion.div animate={{ x: 100 }} />
 *     </MotionConfig>
 *   )
 * }
 * ```
 *
 * @public
 */
function MotionConfig(_a) {
    var children = _a.children, _b = _a.features, features = _b === void 0 ? [] : _b, transition = _a.transition, props = __rest(_a, ["children", "features", "transition"]);
    var pluginContext = useContext(MotionConfigContext);
    var loadedFeatures = __spread(new Set(__spread(pluginContext.features, features)));
    // We do want to rerender children when the number of loaded features changes
    var value = useMemo(function () { return ({
        features: loadedFeatures,
        transition: transition || pluginContext.transition,
    }); }, [loadedFeatures.length, transition]);
    // Mutative to prevent triggering rerenders in all listening
    // components every time this component renders
    for (var key in props) {
        value[key] = props[key];
    }
    return (createElement(MotionConfigContext.Provider, { value: value }, children));
}

export { MotionConfig, MotionConfigContext };
